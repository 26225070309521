import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import styles from './Photos.module.css';

const isDevelopment = process.env.NODE_ENV === 'development';

// Add cache object outside component to persist between renders
const imageCache = new Map();

const getImageUrl = (path, width) => {
  const cacheKey = `${path}-${width}`;
  
  if (imageCache.has(cacheKey)) {
    return imageCache.get(cacheKey);
  }

  if (isDevelopment) {
    imageCache.set(cacheKey, path);
    return path;
  }

  const url = `/.netlify/images?url=${path}&width=${width}`;
  imageCache.set(cacheKey, url);
  return url;
};

const Photos = () => {
  const shuffledPhotos = useMemo(() => {
    const photos = Array.from({ length: 48 }, (_, i) => `${i + 1}.jpeg`);
    for (let i = photos.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [photos[i], photos[j]] = [photos[j], photos[i]];
    }
    return photos;
  }, []);

  const breakpointColumns = {
    default: 3,
    1100: 3,
    800: 2,
    500: 1
  };

  // Cache the processed image data for all photos
  const processedImages = useMemo(() => {
    return shuffledPhotos.map((photo) => {
      const imagePath = `/images/photos/${photo}`;
      
      return {
        key: photo,
        srcSet: isDevelopment 
          ? imagePath
          : `
            ${getImageUrl(imagePath, 400)} 400w,
            ${getImageUrl(imagePath, 800)} 800w,
            ${getImageUrl(imagePath, 1200)} 1200w
          `,
        src: isDevelopment ? imagePath : getImageUrl(imagePath, 800)
      };
    });
  }, [shuffledPhotos]);

  return (
    <>
      <nav className={styles.topNav}>
        <div className={styles.navContent}>
          <Link to="/" className={`${styles.link} ${styles.navLink}`}>
            about me
          </Link>
          <div className={styles.navTitle}>sergio</div>
          <Link to="/photos" className={`${styles.link} ${styles.navLink} ${styles.selected}`}>
            photos
          </Link>
        </div>
      </nav>
      <div className={styles.container}>
        <Masonry
          breakpointCols={breakpointColumns}
          className={styles.myMasonryGrid}
          columnClassName={styles.myMasonryGridColumn}
        >
          {processedImages.map((image, index) => (
            <img
              key={image.key}
              srcSet={image.srcSet}
              sizes="(max-width: 768px) 400px,
                     (max-width: 1200px) 800px,
                     1200px"
              src={image.src}
              alt={`Photo ${index + 1}`}
              loading="lazy"
              decoding="async"
              className={styles.gridImage}
            />
          ))}
        </Masonry>
      </div>
    </>
  );
};

export default Photos; 