import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Main } from './pages/Main';
import Photos from './pages/Photos';
import ZerocamPolicy     from "./pages/zerocam/Policy";
import MonoPolicy     from "./pages/mono/Policy";

import MemoticoPolicy    from "./pages/memotico/Policy";
import MemoticoPolicyEs  from "./pages/memotico/Policy-es";
import MemoticoPolicyIt  from "./pages/memotico/Policy-it";

import ContactSupport    from "./pages/ContactSupport";

import LockSpeedPolicy   from "./pages/lockspeed/Policy";
import LockSpeedPolicyEs from "./pages/lockspeed/Policy-es";
import LockSpeedPolicyIt from "./pages/lockspeed/Policy-it";

function App() {
  return (
    <Router>
      <Routes>
      <Route exact path="/"      element={<Main />} />

<Route exact path="/zerocam/policy"      element={<ZerocamPolicy />} />
<Route exact path="/mono/policy"      element={<MonoPolicy />} />

<Route exact path="/memotico/policy"     element={<MemoticoPolicy />} />
<Route exact path="/memotico/policy-es"  element={<MemoticoPolicyEs />} />
<Route exact path="/memotico/policy-it"  element={<MemoticoPolicyIt />} />

<Route exact path="/contact"             element={<ContactSupport />} />

<Route exact path="/lockspeed/policy"    element={<LockSpeedPolicy />} />
<Route exact path="/lockspeed/policy-es" element={<LockSpeedPolicyEs />} />
<Route exact path="/lockspeed/policy-it" element={<LockSpeedPolicyIt />} />
        <Route path="/" element={<Main />} />
        <Route path="/photos" element={<Photos />} />
      </Routes>
    </Router>
  );
}

export default App;
